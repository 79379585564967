<template>
  <div>
    <!-- <navigation msg="我的企业"></navigation> -->
    <div class="header">
       <van-icon  @click="gouser" size=".35rem" name="arrow-left"/>
       <div class="title">我的企业</div>
       <div class="sx2" @click="gomycompanysx">
         <img class="sx" src="../../assets/filter.png" alt="">
         <span class="sx1">筛选</span>
       </div>
    </div>
    <van-pull-refresh style="height:100vh"  success-text="刷新成功" v-model="isDownLoading" @refresh="onDownRefresh" >
      <van-list class="list3">
          <div v-if="companyList.length>0">
          <div class="companyItem" v-for="(item,index) in companyList" :key="index" @click="gocompanyDetail(item.company_id)">
            <div class="info">
                <img class="companyImg" src="../../assets/mycompany.png" alt="">
                <div>
                  <div class="name">{{item.company}}</div>
                  <div class="color">时间：{{item.entry_time}} - {{item.r_time}}</div>
                  <div v-if="item.status == 2 || item.status == 3 " :class="item.status == 2 ? 'status' : 'nostatus' ">{{item.status | workStatus}}</div>
                   <div v-if="item.status == 1 || item.status == 4 || item.status == 6" :class="item.status == 2 ? 'status' : 'nostatus' ">未入职</div>
                   <div v-if="item.status == 7 || item.status == 5" :class="item.status == 2 ? 'status' : 'nostatus' ">已入职</div>
                </div>
            </div>
            <van-icon  size=".35rem" name="arrow"/>
          </div>
        </div>
        <div class="nomsg" v-else>
            <div v-if="showLoading == false">
            <img  src="../../assets/myqy.png" alt="">
            <div>您还没有任何企业信息哦</div>
        </div>
      </div>
      </van-list>
    </van-pull-refresh>
     <van-loading v-if="showLoading" size="45" class="loading" type="spinner" />
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data(){
    return{
      companyList:[],
      isDownLoading:false,
      showLoading:false
    }
  },
  mounted(){
   this.getCompanyList()
   
  },
  methods:{
    gomycompanysx(){
      this.$router.push("./companySx")
    },
    gouser(){
      this.$router.push("./user")
    },
     onDownRefresh(){
    //  this.getCompanyList()
    },
    getCompanyList(){ 
      let status = this.$route.query.status
      let company_name = this.$route.query.company_name
      console.log(status)
      this.showLoading = true
      this.$http.post("/user/v1/personal_info/myCompanyInfo",{reqType:"myCompanyInfo",pageno:1,limit:15,status:status,company_name:company_name})
      .then((res)=>{
        // console.log(res)
        this.showLoading = false
        this.isDownLoading = false
        let response = JSON.parse(res.data)
        if(response.code == 0){
          console.log(response)
          response.data.companyList.map((item)=>{

            if(item.entry_time == "" && item.r_time == ""){
               item.entry_time = "暂无"
               item.r_time = "至今"
            }

            if(item.entry_time != "" || item.entry_time != null){
               item.entry_time =  item.entry_time.substring(0,item.entry_time.lastIndexOf("-"))
               item.entry_time = item.entry_time.replace("-",".")
            }else{
              item.entry_time = "暂无"
            }
            if( item.r_time != null || item.r_time != ""){
               item.r_time =  item.r_time.substring(0,item.r_time.lastIndexOf("-"))
               item.r_time = item.r_time.replace("-",".")
            }else{
              item.r_time = "至今"
            }
            return item
          })
          this.companyList = response.data.companyList
        }else{
          Toast.fail(response.msg)
        }
        })
      .catch((e)=>{console.log(e)})
    },
    gocompanyDetail(id){
      // console.log(id)
      sessionStorage.setItem("companyId",id)
      this.$router.push({
        path:"/companyDetail",
        // query:{
        //   companyId:id
        // }
      })
    },
  },
}
</script>
<style scoped>
.color{
  margin-top:0.2rem;
  font-size: 0.24rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #959595;
}
.sx1{
  font-size: 0.3rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #333333;
}
.sx2{
  position:absolute;
  right:0.2rem;
}
.sx{
  width:0.25rem;
  height:0.25rem;
  margin-right:0.1rem;
  
}
/* .list3{
  margin-top:0.6rem;
} */
.header{
  height:1rem;
  display:flex;
  align-items: center;
  background-color: #fff;
  padding:0 0.2rem;
  position:relative;
  z-index:9999;
}
.title{
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-left:2.6rem;
}
.nostatus{
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #555555;
  border: 0.01rem solid #555555;
  width:1.74rem;
  height:0.4rem;
  line-height: 0.4rem;
  border-radius:0.03rem;
  text-align: center;
  margin-top:0.17rem;
}
.status{
  font-size: 0.24rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #F55814;
border: 0.01rem solid #F55814;
width:1.74rem;
height:0.4rem;
line-height: 0.4rem;
border-radius:0.03rem;
text-align: center;
margin-top:0.17rem;
}
.nostatus{
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #555555;
  border: 0.01rem solid #555555;
  width:1.74rem;
  height:0.4rem;
  line-height: 0.4rem;
  border-radius:0.03rem;
  text-align: center;
  margin-top:0.17rem;
}
.name{
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.companyImg{
  width: 1.03rem;
  height: 1.03rem;
  margin-right:0.44rem;
}
.companyItem{
  display:flex;
  justify-content: space-between;
  align-items: center;
  background-color:#fff;
  padding:0.41rem 0.3rem;
  margin-top:0.21rem;
}
.info{
  display:flex;
  align-items: center;
}
.loading{
  position:absolute;
  top:50%;
  left:50%;
  margin-left:-0.2rem;
}
.nomsg{
  text-align: center;
  margin-top:2rem;
}
.nomsg img{
  width:3rem;height:2.68rem;
}
.nomsg div{
  height: 0.45rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #B6B6B6;
  line-height: 0.45rem;
}
</style>